import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createStaticVNode as _createStaticVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  class: "menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg-light-primary fw-bold py-4 fs-6 w-275px",
  "data-kt-menu": "true"
}
const _hoisted_2 = { class: "menu-item px-3" }
const _hoisted_3 = { class: "menu-content d-flex align-items-center px-3" }
const _hoisted_4 = { class: "d-flex flex-column" }
const _hoisted_5 = { class: "fw-bolder d-flex align-items-center fs-5" }
const _hoisted_6 = {
  href: "#",
  class: "fw-bold text-muted text-hover-primary fs-7"
}
const _hoisted_7 = {
  class: "menu-item px-5",
  "data-kt-menu-trigger": "hover",
  "data-kt-menu-placement": "left-start",
  "data-kt-menu-flip": "center, top"
}
const _hoisted_8 = { class: "menu-sub menu-sub-dropdown w-175px py-4" }
const _hoisted_9 = { class: "menu-item px-3" }
const _hoisted_10 = { class: "menu-item px-3" }
const _hoisted_11 = { class: "menu-item px-3" }
const _hoisted_12 = { class: "menu-item px-3" }
const _hoisted_13 = {
  class: "menu-item px-5",
  "data-kt-menu-trigger": "hover",
  "data-kt-menu-placement": "left-start",
  "data-kt-menu-flip": "center, top"
}
const _hoisted_14 = { class: "menu-sub menu-sub-dropdown w-175px py-4" }
const _hoisted_15 = { class: "menu-item px-3" }
const _hoisted_16 = { class: "menu-item px-3" }
const _hoisted_17 = { class: "menu-item px-3" }
const _hoisted_18 = { class: "menu-item px-3" }
const _hoisted_19 = { class: "menu-item px-3" }
const _hoisted_20 = { class: "menu-item px-5" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _cache[6] || (_cache[6] = _createElementVNode("div", { class: "symbol symbol-50px me-5" }, [
          _createElementVNode("img", {
            alt: "Logo",
            src: "media/avatars/blank.png"
          })
        ], -1)),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.userName), 1),
          _createElementVNode("a", _hoisted_6, _toDisplayString(_ctx.userEmail), 1)
        ])
      ])
    ]),
    _cache[17] || (_cache[17] = _createElementVNode("div", { class: "separator my-2" }, null, -1)),
    _cache[18] || (_cache[18] = _createElementVNode("div", { class: "menu-item px-5" }, null, -1)),
    _cache[19] || (_cache[19] = _createElementVNode("div", { class: "menu-item px-5" }, null, -1)),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createElementVNode("div", _hoisted_9, [
          _createVNode(_component_router_link, {
            to: "/pages/profile/overview",
            class: "menu-link px-5"
          }, {
            default: _withCtx(() => _cache[7] || (_cache[7] = [
              _createTextVNode(" Referrals ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_router_link, {
            to: "/pages/profile/overview",
            class: "menu-link px-5"
          }, {
            default: _withCtx(() => _cache[8] || (_cache[8] = [
              _createTextVNode(" Billing ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_router_link, {
            to: "/pages/profile/overview",
            class: "menu-link px-5"
          }, {
            default: _withCtx(() => _cache[9] || (_cache[9] = [
              _createTextVNode(" Payments ")
            ])),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_router_link, {
            to: "/pages/profile/overview",
            class: "menu-link d-flex flex-stack px-5"
          }, {
            default: _withCtx(() => _cache[10] || (_cache[10] = [
              _createTextVNode(" Statements "),
              _createElementVNode("i", {
                class: "fas fa-exclamation-circle ms-2 fs-7",
                "data-bs-toggle": "tooltip",
                title: "View your statements"
              }, null, -1)
            ])),
            _: 1
          })
        ]),
        _cache[11] || (_cache[11] = _createStaticVNode("<div class=\"separator my-2\"></div><div class=\"menu-item px-3\"><div class=\"menu-content px-3\"><label class=\"form-check form-switch form-check-custom form-check-solid\"><input class=\"form-check-input w-30px h-20px\" type=\"checkbox\" value=\"1\" checked=\"checked\" name=\"notifications\"><span class=\"form-check-label text-muted fs-7\"> Notifications </span></label></div></div>", 2))
      ])
    ]),
    _cache[20] || (_cache[20] = _createElementVNode("div", { class: "menu-item px-5" }, null, -1)),
    _cache[21] || (_cache[21] = _createElementVNode("div", { class: "separator my-2" }, null, -1)),
    _createElementVNode("div", _hoisted_13, [
      _createElementVNode("div", _hoisted_14, [
        _createElementVNode("div", _hoisted_15, [
          _createElementVNode("a", {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setLang('en'))),
            href: "#",
            class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage('en') }])
          }, _cache[12] || (_cache[12] = [
            _createElementVNode("span", { class: "symbol symbol-20px me-4" }, [
              _createElementVNode("img", {
                class: "rounded-1",
                src: "media/flags/united-states.svg",
                alt: "metronic"
              })
            ], -1),
            _createTextVNode(" English ")
          ]), 2)
        ]),
        _createElementVNode("div", _hoisted_16, [
          _createElementVNode("a", {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setLang('es'))),
            href: "#",
            class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage('es') }])
          }, _cache[13] || (_cache[13] = [
            _createElementVNode("span", { class: "symbol symbol-20px me-4" }, [
              _createElementVNode("img", {
                class: "rounded-1",
                src: "media/flags/spain.svg",
                alt: "metronic"
              })
            ], -1),
            _createTextVNode(" Spanish ")
          ]), 2)
        ]),
        _createElementVNode("div", _hoisted_17, [
          _createElementVNode("a", {
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setLang('de'))),
            href: "#",
            class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage('de') }])
          }, _cache[14] || (_cache[14] = [
            _createElementVNode("span", { class: "symbol symbol-20px me-4" }, [
              _createElementVNode("img", {
                class: "rounded-1",
                src: "media/flags/germany.svg",
                alt: "metronic"
              })
            ], -1),
            _createTextVNode(" German ")
          ]), 2)
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("a", {
            onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setLang('ja'))),
            href: "#",
            class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage('ja') }])
          }, _cache[15] || (_cache[15] = [
            _createElementVNode("span", { class: "symbol symbol-20px me-4" }, [
              _createElementVNode("img", {
                class: "rounded-1",
                src: "media/flags/japan.svg",
                alt: "metronic"
              })
            ], -1),
            _createTextVNode(" Japanese ")
          ]), 2)
        ]),
        _createElementVNode("div", _hoisted_19, [
          _createElementVNode("a", {
            onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setLang('fr'))),
            href: "#",
            class: _normalizeClass(["menu-link d-flex px-5", { active: _ctx.currentLanguage('fr') }])
          }, _cache[16] || (_cache[16] = [
            _createElementVNode("span", { class: "symbol symbol-20px me-4" }, [
              _createElementVNode("img", {
                class: "rounded-1",
                src: "media/flags/france.svg",
                alt: "metronic"
              })
            ], -1),
            _createTextVNode(" French ")
          ]), 2)
        ])
      ])
    ]),
    _cache[22] || (_cache[22] = _createElementVNode("div", { class: "menu-item px-5 my-1" }, null, -1)),
    _createElementVNode("div", _hoisted_20, [
      _createElementVNode("a", {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.signOut())),
        class: "menu-link px-5"
      }, " Sign Out ")
    ])
  ]))
}